// extracted by mini-css-extract-plugin
export var Body = "thankYou-module--Body--91561";
export var Container = "thankYou-module--Container--96533";
export var FlexCol = "thankYou-module--FlexCol--ca31d";
export var FlexWrap = "thankYou-module--FlexWrap--18e1a";
export var H2 = "thankYou-module--H2--7cbe7";
export var H3 = "thankYou-module--H3--52636";
export var H4 = "thankYou-module--H4--7f989";
export var H5 = "thankYou-module--H5--351de";
export var H6 = "thankYou-module--H6--c0934";
export var HeaderWrap = "thankYou-module--HeaderWrap--83e23";
export var LargeBody = "thankYou-module--LargeBody--ef7fc";
export var ThankYou = "thankYou-module--ThankYou--7f852";
export var articleMeta = "thankYou-module--articleMeta--e9f65";
export var btnPrimary = "thankYou-module--btnPrimary--918ab";
export var readLink = "thankYou-module--readLink--1b7ff";
export var viewAll = "thankYou-module--viewAll--16eb0";